import React from "react";
import { Row, Col } from "react-bootstrap";

const CampCardSkeleton = () => {
  return (
    <div className="p-3 border rounded my-2">
    {/* Top Section */}
    <Row className="align-items-center mb-3">
      <Col xs={2}>
        <div
          className="bg-light rounded-circle"
          style={{ height: "50px", width: "50px" }}
        ></div>
      </Col>
      <Col xs={10}>
        <div className="bg-light mb-2" style={{ height: "20px", width: "60%" }}></div>
        <div className="bg-light mb-2" style={{ height: "15px", width: "40%" }}></div>
        <div className="bg-light mb-2" style={{ height: "15px", width: "50%" }}></div>
      </Col>
    </Row>

    {/* Middle Section */}
    <Row className="mb-3">
      <Col>
        <div className="bg-light mb-2" style={{ height: "15px", width: "90%" }}></div>
        <div className="bg-light mb-2" style={{ height: "15px", width: "80%" }}></div>
        <div className="bg-light mb-2" style={{ height: "15px", width: "70%" }}></div>
      </Col>
    </Row>

    {/* Bottom Section */}
    <Row className="align-items-center">
      <Col xs={6}>
        <div className="bg-light" style={{ height: "15px", width: "70%" }}></div>
      </Col>
      <Col xs={6} className="text-end">
        <div className="bg-light" style={{ height: "30px", width: "50%" }}></div>
      </Col>
    </Row>
  </div>
  );
};

export default CampCardSkeleton;